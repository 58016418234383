<!-- 
author: 张洪志
description: 资管计划
 -->
<template>
  <section class="asset-manage"></section>
</template>

<script>
  export default{
    name: 'AssetMange'
  }
</script>

<style scoped></style>
